<h1 mat-dialog-title>{{ 'title.new_dashboard' | translate }}</h1>
<div mat-dialog-content class="mat-typography">
    <form [formGroup]="form" class="flex flex-col items-stretch gap-2">
        <div>
            <div class="mat-subtitle-2">
                {{ 'dashboard.name' | translate }}
            </div>
            <sz-input type="text" formControlName="name" />
        </div>
        <div>
            <div class="mat-subtitle-2">
                {{ 'dashboard.template' | translate }}
            </div>
            <sz-select formControlName="template" [options]="templates" />
        </div>
    </form>
    <sz-dialog-button-bar
        [primaryDisabled]="form.invalid"
        (primaryClick)="handleSave()"
        (secondaryClick)="dialogRef.close()"
    />
</div>
