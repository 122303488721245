import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/api/api.service';
import { parseDashboardContent } from 'src/app/shared/models/dashboard';

@Component({
    templateUrl: './import-json-dialog.component.html',
})
export class ImportJsonDialog implements OnInit {
    dashboardJson = new FormControl('', [Validators.required]);
    isValidJson = false;

    constructor(
        public dialogRef: MatDialogRef<ImportJsonDialog>,
        private router: Router,
        public translate: TranslateService,
        private api: ApiService
    ) {}

    ngOnInit(): void {
        this.dashboardJson.valueChanges.subscribe((value) => {
            this.isValidJson = false;
            const keysToCheck = ['name', 'customerId', 'content', 'deviceIds'];
            try {
                const parsed = JSON.parse(value);
                if (parsed && typeof parsed === 'object') {
                    keysToCheck.forEach((key) => {
                        if (!(key in parsed)) {
                            this.isValidJson = false;
                            return;
                        }
                        this.isValidJson = true;
                    });
                }
            } catch {
                this.isValidJson = false;
            }
        });
    }

    async handleImportJson() {
        const dashboard = JSON.parse(this.dashboardJson.value);
        dashboard.content = parseDashboardContent(dashboard.content);
        const newDashboard = await this.api.dashboards.create(dashboard);
        if (newDashboard) {
            this.router.navigate([`dashboards/${newDashboard['id']}`]);
            this.dialogRef.close(true);
        }
    }
}
